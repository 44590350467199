<template>
    <div v-if="createReport" class="section section--detail-list section--detail-list-export">
        <div class="detail-list-container">
            <div class="report-title">
                Report {{ name }}
            </div>
            <div class="export-buttons">
                <!-- <downloadexcel class="button button--brandFull button--rounded" :name="`Report-${name}`" :data="itemList" :fields="(worker) ? json_fields_worker : json_fields_shop " :footer="footer">
                    <svg class="icon icon-download">
                        <use xlink:href="#download"></use>
                    </svg>
                    Export Excel
                </downloadexcel> -->
                <Button buttonType="brand" :rounded="true" text="Export Excel" icon="download" @click="download()" />
            </div>
            <div class="header-list">
                <div class="heading">Data</div>
                <div class="heading">Giorno</div>
                <div class="heading">Nome</div>
                <div class="heading">Numero ore</div>
                <div class="heading">Orario</div>
                <div class="heading">Note</div>
            </div>
            <div class="main-list">
                <div class="worker" v-for="(item, index) in itemList" :key="index">
                    <div class="list-item list-item--date">{{ dayjs(item.date).format('DD-MM-YYYY') }}</div>
                    <div class="list-item list-item--date">{{ dayjs(item.date).locale('it').format('dddd') }}</div>
                    <div class="list-item list-item--name">{{ (worker) ? shopName(item.shop_id) : workerName(item.user_id) }}</div>
                    <div class="list-item list-item--tot-hours">{{ (actualShifts == false) ? totHoursCounter(item.time_start, item.time_end) : item.tot_hours_worked }}</div>
                    <div class="list-item list-item--hours">{{ time(item) }}</div>
                    <div class="list-item list-item--message">{{ item.message }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

// import workersList from '../models/workers.json'
import dayjs from 'dayjs';
// import downloadexcel from 'vue-json-excel3';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import Button from '../../components/tools/Button.vue'
import { totHoursCounter, reportExcelJson } from '../../dataService';
import * as XLSX from 'xlsx/xlsx.mjs';

dayjs.extend(dayOfYear)
require('dayjs/locale/it')

</script>

<script>

export default {
    name: 'detail-list',
    components: {
        // downloadexcel,
        Button
    },
    props: {
        actualShifts: {
            default: false,
            type: Boolean
        },
        itemList: {
            default: null,
            type: Object
        },
        createReport: {
            default : false,
            type : Boolean
        },
        reportName: {
            default : null,
            type : String
        },
        reportDate: {
            default : null,
            type : String
        },
        worker : {
            default : true,
            type : Boolean
        }
    },
    data() {
        return {
            pageNr: 0,
            heading: null,
            // json_fields_worker : {
            //     "Nome" : {
            //         field : "shop_id",
            //         callback: (value) => {
            //             var items = JSON.parse(localStorage.getItem('shopsList')) 
            //             var name
            //             Array.from(items).map((item) => {
            //                 if(item.id == value) name = item.full_name
            //             })
            //             return name
            //         }
            //     },
            //     "Data" : {
            //         field : "date",
            //         callback: (value) => {
            //             return dayjs(value).format('DD-MM-YYYY')
            //         }
            //     },
            //     "Dalle" : {
            //         field : (this.actualShifts == false) ? "time_start" : "check_in",
            //         callback: (value) => {
            //             return (value !== null) ? value.slice(0, 5) : ''
            //         }
            //     },
            //     "Alle" : {
            //         field : (this.actualShifts == false) ? "time_end" : "check_out",
            //         callback: (value) => {
            //             return (value !== null) ? value.slice(0, 5) : ''
            //         }
            //     },
            //     "Attività" : {
            //         field : "shop_id",
            //         callback : (value) => {
            //             var items = JSON.parse(localStorage.getItem('shopsList')) 
            //             var name
            //             Array.from(items).map((item) => {
            //                 if(item.id == value) name = item.full_name
            //             })
            //             return name
            //         }
            //     },
            //     "Numero ore" : {
            //         field : "id",
            //         callback : (value) => {
            //             var tot_hours
            //             Array.from(this.itemList).map((item) => {
            //                 if(item.id == value){
            //                     tot_hours = (this.actualShifts == false) ? totHoursCounter(item.time_start, item.time_end) 
            //                                 : (item.tot_hours_worked !== null) ? item.tot_hours_worked : 0
            //                 }
            //             })

            //             return tot_hours
            //         }
            //     },
            //     "Note" : "message"
            // },
            // json_fields_shop : {
            //     "Nome" : {
            //         field : "user_id",
            //         callback: (value) => {
            //             var items = JSON.parse(localStorage.getItem('workersList')) 
            //             var name
            //             Array.from(items).map((item) => {
            //                 if(item.id == value) name = item.full_name
            //             })
            //             return name
            //         }
            //     },
            //     "Data" : {
            //         field : "date",
            //         callback: (value) => {
            //             return dayjs(value).format('DD-MM-YYYY')
            //         }
            //     },
            //     "Dalle" : {
            //         field : (this.actualShifts == false) ? "time_start" : "check_in",
            //         callback: (value) => {
            //             return (value !== null) ? value.slice(0, 5) : ''
            //         }
            //     },
            //     "Alle" : {
            //         field : (this.actualShifts == false) ? "time_end" : "check_out",
            //         callback: (value) => {
            //             return (value !== null) ? value.slice(0, 5) : ''
            //         }
            //     },
            //     "Gruppo" : {
            //         field : "shop_id",
            //         callback: (value) => {
            //             var items = JSON.parse(localStorage.getItem('shopsList')) 
            //             var groups = JSON.parse(localStorage.getItem('groupsList'))
            //             var name
            //             Array.from(items).map((item) => {
            //                 if(item.id == value){
            //                     Array.from(groups).map((group) => {
            //                         if(group.id == item.group_id){
            //                             name = group.group_name
            //                         }
            //                     })
            //                 }
            //             })
            //             return name
            //         }
            //     },
            //     "Numero ore" : {
            //         field : "id",
            //         callback : (value) => {
            //             var tot_hours
            //             Array.from(this.itemList).map((item) => {
            //                 if(item.id == value){
            //                     tot_hours = (this.actualShifts == false) ? totHoursCounter(item.time_start, item.time_end) 
            //                                 : (item.tot_hours_worked !== null) ? item.tot_hours_worked : 0
            //                 }
            //             })

            //             return tot_hours
            //         }
            //     },
            //     "Note" : "message"
            // },
        }
    },
    emits: ['visible', 'edit', 'delete', 'archive'],
    methods: {
        shopName(id){
            var shops = JSON.parse(localStorage.getItem('shopsList'))
            var name;
            Array.from(shops).map((shop) => {
                if(id == shop.id){
                    name = shop.full_name
                }
            })

            return name
        },
        workerName(id){
            var workers = JSON.parse(localStorage.getItem('workersList')) 
            var name;
            Array.from(workers).map((worker) => {
                if(id == worker.id){
                    name = worker.full_name
                }
            })

            return name
        },
        download(){
            this.heading = [["Nome", "Data", "Dalle", "Alle", "Numero ore", "Note"]]
            const JSON = reportExcelJson(this.itemList, this.worker, this.actualShifts)
            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.json_to_sheet([])
            XLSX.utils.sheet_add_aoa(worksheet, this.heading)
            XLSX.utils.sheet_add_json(worksheet, JSON, { origin: 'A2', skipHeader: true })
            XLSX.utils.sheet_add_aoa(worksheet, [[this.footer]], { origin:-1 })
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Foglio 1')
            XLSX.writeFile(workbook, `${this.name}.xlsx`)
        },
        time(item){
            if(this.actualShifts == false){
                return item.time_start.substring(0, 5) + ' - ' + item.time_end.substring(0, 5)
            }else{
                return this.check(item.check_in) + ' - ' + this.check(item.check_out)
            }
        },
        check(el){
            if(el !== null) return el.substring(0, 5); else return '';
        }
    },
    computed : {
        jsonData(){
            return JSON.stringify(this.itemList)
        },
        footer(){
            var totHours = 0;
            Array.from(this.itemList).map((item) => {
                if(this.actualShifts == false) totHours += totHoursCounter(item.time_start, item.time_end);
                else totHours += item.tot_hours_worked
            })

            return 'Ore totali ' + totHours
        },
        name(){
            var list = (this.worker == true) ? JSON.parse(localStorage.getItem('workersList')) : JSON.parse(localStorage.getItem('shopsList'))
            var name;

            Array.from(list).map((item) => {
                if(item.id == this.reportName){
                    name = item.full_name
                }
            })

            return `${name} ${this.reportDate}`
        }
    },
    mounted(){
        
    }
}

</script>