<template>
    <div class="section section--detail-list section--detail-list-sales-points">
        <div class="detail-list-container">
            <div class="pagination" v-if="pages > 1">
                <div class="prev" @click="prev()">
                    <svg class="icon icon-chevron-left">
                        <use xlink:href="#chevron-left"></use>
                    </svg>
                </div>
                <div v-for="(page, j) in pages" :key="j" class="page" :class="(pageNr == j) ? ' active' : ''" @click="pageNr = j">{{ j + 1 }}</div>
                <div class="next" @click="next()">
                    <svg class="icon icon-chevron-left">
                        <use xlink:href="#chevron-left"></use>
                    </svg>
                </div>
            </div>
            <div class="header-list">
                <div class="heading">Nome</div>
                <div class="heading">Gruppo</div>
                <div class="heading">Indirizzo</div>
                <div class="heading">Monte ore</div>
                <div class="heading">Ispettore</div>
                <div class="heading">Azioni</div>
            </div>
            <div v-for="(page, x) in pages" :key="x">
                <div v-if="x == pageNr" class="main-list">
                <div class="worker" v-for="(item, index) in itemList.filter((item, index) => index >= (x * 20) && index < (x * 20) + 20)" :key="index">
                    <div class="list-item list-item--name">{{ item.full_name }}</div>
                    <div class="list-item list-item--address">{{ groupList(item.group_id) }}</div>
                    <div class="list-item list-item--group">{{ item.address }}</div>
                    <div class="list-item list-item--group">{{ item.num_monthly_hours }}</div>
                    <div class="list-item list-item--inspector">{{ inspectorList(item.inspector_id) }}</div>
                    <div class="list-item list-item--actions">
                        <div class="icon-container" @click="$emit('visible', true, item, getNotes(item.id))">
                            <svg class="icon icon-visibility">
                                <use xlink:href="#visibility"></use>
                            </svg>
                        </div>
                        <div class="icon-container" @click="$emit('edit', true, item, getNotes(item.id))">
                            <svg class="icon icon-edit_square">
                                <use xlink:href="#edit-square"></use>
                            </svg>
                        </div>
                        <div v-if="item.status == 'attivo'" class="icon-container" @click="$emit('archive', true, item)">
                            <svg class="icon icon-folder">
                                <use xlink:href="#folder"></use>
                            </svg>
                        </div>
                        <div v-else class="icon-container" @click="$emit('active', true, item)">
                            <svg class="icon icon-folder">
                                <use xlink:href="#reset"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="pagination" v-if="pages > 1">
                <div class="prev" @click="prev()">
                    <svg class="icon icon-chevron-left">
                        <use xlink:href="#chevron-left"></use>
                    </svg>
                </div>
                <div v-for="(page, j) in pages" :key="j" class="page" :class="(pageNr == j) ? ' active' : ''" @click="pageNr = j">{{ j + 1 }}</div>
                <div class="next" @click="next()">
                    <svg class="icon icon-chevron-left">
                        <use xlink:href="#chevron-left"></use>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

// import workersList from '../models/workers.json'
import NotesService from '../../services/notesService';
// import GroupService from '../../services/groupService';
// import WorkerService from '../../services/workerService';

</script>

<script>

export default {
    name : 'detail-list',
    components : {},
    props : {
        itemList : {
            default : null,
            type : Object
        }
    },
    data(){
        return{
            pageNr : 0,
        }
    },
    computed : {
        pages(){
            if(this.itemList.length < 20){
                return 1
            }else{
                if(this.itemList.length % 20 == 0 ){
                    return Math.floor(this.itemList.length / 20)
                }else{
                    return Math.floor(this.itemList.length / 20) + 1
                }
            }
        },
        
    },
    emits : ['visible', 'edit', 'delete', 'archive'],
    methods : {
        next(){
            if(this.pageNr < this.pages - 1){
                this.pageNr++
            }
        },
        async getNotes(id){
            var notes = [];
            var allNotes = await NotesService.getNotes(localStorage.getItem('token'))

            Array.from(allNotes).map((note) => {
                if(note.shop_id == id){
                    notes.push(note)
                }
            })

            return notes
        },
        prev(){
            if(this.pageNr > 0){
                this.pageNr--
            }
        },
        groupList(id){
            var groups = JSON.parse(localStorage.getItem('groupsList'))
            var group
            groups.map((item) => {
                if(id == item.id){
                    group = item.group_name
                }
            })

            return group
        },
        inspectorList(id){
            var workers = JSON.parse(localStorage.getItem('workersList'))
            var inspector
            workers.map((worker) => {
                if(worker.id == id){
                    inspector = worker.full_name
                }
            })

            return inspector
        }
    }
}

</script>