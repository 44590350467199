<template>
    <div class="qr-code-container">
        <figure class="qr-code">
            <VueQrcode :value="stringValue" :options="options" />
            <img class="qr-code--image" src="/assets/images/logo.png" alt="logo" />
        </figure>
    </div>
</template>

<script>

import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
    name : 'qr-code-template',
    components : {
        VueQrcode,
    },
    props : {
        shop_options : {
            default: null,
            type: String
        }
    },
    data(){
        return {
            options: {
                maskPattern: 7,
                scale: 20,
                color: {
                    dark: "#2E90FA",
                    light: "#ffffff",
                },
                margin: 0,
            },
            generated: false
        }
    },
    computed : {
        stringValue(){
            return 'informitalia-shop-id_' + this.shop_options.id
        }
    }
}

</script>

<style scoped>

.qr-code-container{
    align-items: center;
    display: flex;
    height: 600px;
    justify-content: center;
    width: 100%;
}

.qr-code{
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
}

.qr-code--image{
    background-color: #fff;
    border: 0.25rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    height: auto;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
}

</style>