<template>
    <div :id="resource" class="calendar-day" :class="day" @click="$emit('shiftTime', $event.target)">
        <div class="morning-shift-container" :class="event(morning.worker)">
            <div v-if="morning" class="morning-shift">
                <div class="worker">
                    {{ morning.worker }}
                </div>
                <div v-if="morning.time_start !== null" class="shift">
                    <div v-if="morning.status !== null" class="status"
                        :class="status(morning.status)"></div>
                    <div v-if="actualShifts == false" class="time">
                        {{ morning.time_start }} - {{ morning.time_end }}
                    </div>
                    <div v-if="actualShifts == true" class="time">
                        {{ (morning.check_in !== null) ? morning.check_in : '' }} - 
                        {{ (morning.check_out !== null) ? morning.check_out : '' }}
                    </div>
                </div>
                <div v-if="morning.message !== null && morning.message !== '' && morning.message !== undefined" class="message-notification">
                    <svg class="icon icon-notification">
                        <use xlink:href="#notifications"></use>
                    </svg>
                </div>
            </div>
        </div>
        <div class="afternoon-shift-container" :class="event(afternoon.worker)">
            <div v-if="afternoon" class="afternoon-shift">
                <div class="worker">
                    {{ afternoon.worker }}
                </div>
                <div v-if="afternoon.time_start !== null" class="shift">
                    <div v-if="afternoon.status !== null" class="status" :class="status(afternoon.status)"></div>
                    <div v-if="actualShifts == false" class="time">
                        {{ afternoon.time_start }} - {{ afternoon.time_end }}
                    </div>
                    <div v-if="actualShifts == true" class="time">
                        {{ (afternoon.check_in !== null) ? afternoon.check_in : '' }} - 
                        {{ (afternoon.check_out !== null) ? afternoon.check_out : '' }}
                    </div>
                </div>
                <div v-if="afternoon.message !== null && afternoon.message !== '' && afternoon.message !== undefined"
                    class="message-notification">
                    <svg class="icon icon-notification">
                        <use xlink:href="#notifications"></use>
                    </svg>
                </div>
            </div>
        </div>
        <div class="evening-shift-container" :class="event(evening.worker)">
            <div v-if="evening" class="evening-shift">
                <div class="worker">
                    {{ evening.worker }}
                </div>
                <div v-if="evening.time_start !== null" class="shift">
                    <div v-if="evening.status !== null" class="status" :class="status(evening.status)"></div>
                    <div v-if="actualShifts == false" class="time">
                        {{ evening.time_start }} - {{ evening.time_end }}
                    </div>
                    <div v-if="actualShifts == true" class="time">
                        {{ (evening.check_in !== null) ? evening.check_in : '' }} - 
                        {{ (evening.check_out !== null) ? evening.check_out : '' }}
                    </div>
                </div>
                <div v-if="evening.message !== null && evening.message !== '' && evening.message !== undefined"
                    class="message-notification">
                    <svg class="icon icon-notification">
                        <use xlink:href="#notifications"></use>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Scheduler-Shift',
    props: {
        actualShifts: {
            default: false,
            type: Boolean
        },
        day: {
            default: null,
            type: String
        },
        resource: {
            default: null,
            type: String
        },
        morning: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },

            time_end: {
                default: null,
                type: String
            },
            check_in: {
                default: null,
                type: String
            },
            check_out: {
                default: null,
                type: String
            },
            status: {
                default: null,
                type: String
            }
        },
        afternoon: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },

            time_end: {
                default: null,
                type: String
            },
            check_in: {
                default: null,
                type: String
            },
            check_out: {
                default: null,
                type: String
            },
            status: {
                default: null,
                type: String
            }
        },
        evening: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },

            time_end: {
                default: null,
                type: String
            },
            check_in: {
                default: null,
                type: String
            },
            check_out: {
                default: null,
                type: String
            },
            status: {
                default: null,
                type: String
            }
        }
    },
    data() {
        return {
            morningTime: null,
            afternoonTime: null,
            eveningTime: null,
        }
    },
    emits: ['shiftTime'],
    methods: {
        event(elem) {
            if (elem !== null) {
                return ' event';
            } else {
                return '';
            }
        },
        status(string) {
            if (string == 'svolto') return 'success'
            else if (string == 'non svolto') return 'error'
            else if (string == 'inconcluso') return 'invalid'
            else return 'warning'
        },
        // time(period, bool) {
        //     if (bool == true) {
        //         return this.check(period.check_in) + ' - ' + this.check(period.check_out);
        //     } else {
        //         return period.time_start + ' - ' + period.time_end;
        //     }
        // },
        // check(el) {
        //     if (el !== null) return el; else return '';
        // }
    },
    watch: {
        // actualShifts(value) {
        //     this.morningTime = this.time(this.morning, value)
        //     this.afternoonTime = this.time(this.afternoon, value)
        //     this.eveningTime = this.time(this.evening, value)
        // },
        // morning(value) {
        //     console.log(value)
        //     this.morningTime = this.time(value, this.actualShifts)
        // },
        // afternoon(value) {
        //     console.log(value)
        //     this.afternoonTime = this.time(value, this.actualShifts)
        // },
        // evening(value) {
        //     console.log(value)
        //     this.eveningTime = this.time(value, this.actualShifts)
        // }
    },
    mounted() {
        // this.morningTime = this.time(this.morning, this.actualShifts)
        // this.afternoonTime = this.time(this.afternoon, this.actualShifts)
        // this.eveningTime = this.time(this.evening, this.actualShifts)
    },
}

</script>