<template>
    <div class="login-container">
        <div class="login-wrapper">
            <div class="avatar">
                <div class="avatar-image">
                        <img src="/assets/images/logo-info.png" alt="">
                    </div>
                <div class="avatar-name">Informitalia</div>
            </div>
            <div class="title">
                Login
            </div>
            <FormInput labelText="Username" v-model:value="userData.userName" @login="login"/>
            <FormInput type="password" labelText="Password" v-model:value="userData.userPassword" @login="login"/>
            <div v-if="error" class="error">
                Username e/o password errati
            </div>
            <input type="submit" class="submit" @click="login()" value="Accedi">
        </div>
    </div>
</template>

<script setup>

import FormInput from '../components/tools/FormInput.vue';
import Login from '../services/loginService';
import dayjs from 'dayjs';
import ShopService from '../services/shopService'
import WorkerService from '../services/workerService'
import GroupService from '../services/groupService'
import ScheduleService from '../services/scheduleService'

</script>

<script>

export default {
    name : 'app-login',
    components : {
        FormInput
    },
    data(){
        return{
            userData : {
                userName : null,
                userPassword : null
            },
            error : false
        }
    },
    computed : {
        data(){
            var data = new FormData();
            data.append('email', this.userData.userName)
            data.append('password', this.userData.userPassword)

            return data
        }
    },
    methods : {
        async login(){
            const isAuthenticated = await Login.userLogin(this.data)
            
            
            if(isAuthenticated){

                localStorage.setItem('expiry', dayjs().unix() + 240*60*1000)
                localStorage.setItem('shopsList', JSON.stringify(await ShopService.getShops(localStorage.getItem('token'))))
                localStorage.setItem('workersList', JSON.stringify(await WorkerService.getWorkers(localStorage.getItem('token'))))
                localStorage.setItem('groupsList', JSON.stringify(await GroupService.getGroups(localStorage.getItem('token'))))
                localStorage.setItem('schedulesList', JSON.stringify(await ScheduleService.getAllSchedules(localStorage.getItem('token'))))
                this.$router.push('/')

            }else{
                this.error = true
            }
        }
    }


}

</script>