<template>
    <div class="section section--form">
        <div ref="reportForm" class="form-container">
            <FormInput v-model:value="startDate" @click="week = true" @input="week = true" :class="(week) ? 'outline' : ''"
                type="date" name="date-from" labelText="A partire dal" icon="calendar-today" />
            <FormInput v-model:value="endDate" @click="week = true" @input="week = true" :class="(week) ? 'outline' : ''"
                type="date" name="date-to" labelText="Fino al" icon="calendar-today" />
            <FormInput v-model:value="month" type="month" @click="week = false" @input="week = false"
                :class="(!week) ? 'outline' : ''" labelText="Mese" icon="calendar-today" />
            <FormSelect v-model:value="worker" @click="workerSearch = true, createReport = false" :class="(workerSearch) ? 'outline' : ''"
                :worker-select="true" id="workers-autocomplete" name="workers" labelText="Operatore"
                :item-list="workersList" icon="search" />
            <FormSelect v-model:value="shop" @click="workerSearch = false, createReport = false" :class="(!workerSearch) ? 'outline' : ''"
                :worker-select="true" id="sales-points-autocomplete" name="sales-points" labelText="Punto vendita"
                :item-list="salesPointsList" icon="search" />
            <div class="checkbox-container">
                <div class="label">Turno effettivo</div>
                <label class="switch">
                    <input type="checkbox" :name="name" v-model="actualShifts">
                    <span class="slider"><span class="yes">Sì</span></span>
                </label>
            </div>
        </div>
        <div class="edit-buttons">
            <Button buttonType="brandFull" :rounded="true" text="Genera report" icon="edit" @click="generateReport()" />
            <Button buttonType="brand" :rounded="true" text="Reset Campi" icon="edit" @click="reset()" />
        </div>
        <div v-if="error" class="date-error error">
            Selezionare un intervallo di tempo più un operatore o un negozio
        </div>
    </div>
    <ReportList :createReport="createReport" :worker="workerSearch" :itemList="itemsList"
        :report-date="(week) ? startDate + ' ' + endDate : month" :report-name="(workerSearch) ? worker : shop" :actualShifts="actualShifts" />
</template>

<script setup>

import FormInput from '../tools/FormInput.vue';
import FormSelect from '../tools/FormSelect.vue';
import Button from '../tools/Button.vue';
import ExportService from '../../services/exportService'
import ReportList from '../export/ReportList.vue';
import dayjs from 'dayjs';
import { alphabetic, alphaShop } from '../../dataService';




</script>

<script>

export default {

    name: 'report-form',
    components: {
        Button,
        FormInput,
        FormSelect,
        ReportList,
    },
    props: {
        rows: {
            default: 2,
            type: Number
        },
        cols: {
            default: 3,
            type: Number
        },
        fields: {
            default: 1,
            type: Number
        }
    },
    data() {
        return {
            // reportLis
            actualShifts: false,
            createReport: false,
            endDate: null,
            error: false,
            itemsList: null,
            month: null,
            salesPointsList: [],
            shop: null,
            startDate: null,
            week: true,
            worker: null,
            workerSearch: true,
            workersList: [],
        }
    },
    methods: {
        async generateReport() {

            if (((this.startDate !== null && this.endDate !== null) || this.month !== null) && (this.worker !== null || this.shop !== null)) {

                var reportData = {
                    start_date: (this.week) ? this.startDate : this.month + '-01',
                    end_date: (this.week) ? this.endDate : dayjs(this.month + '-01').endOf('month').format('YYYY-MM-DD'),
                    type: (!this.workerSearch) ? 'shop' : 'user',
                    id: (this.workerSearch) ? this.worker : this.shop
                }

                this.itemsList = await ExportService.getExports(localStorage.getItem('token'), reportData)
                // this.worker = localStorage.getItem('export_name')
                this.error = false
                this.createReport = true

            } else {
                this.error = true
                this.createReport = false
                setTimeout(() => {
                    this.error = false
                }, 2000)
            }
        },
        reset() {
            this.$router.go()
        }
    },
    async mounted() {
        var salesPointsList = []
        Array.from(JSON.parse(localStorage.getItem('shopsList'))).map((shop) => {
            if(shop.status == 'attivo'){
                salesPointsList.push(shop)
            }
        })

        var workersList = [];
        Array.from(JSON.parse(localStorage.getItem('workersList'))).map((worker) => {
            if (worker.role == 'operatore' && worker.status == 'attivo') {
                workersList.push(worker)
            }
        })

        this.workersList = workersList.sort(alphabetic)
        this.salesPointsList = salesPointsList.sort(alphaShop)
    }

}

</script>