<template>
    <div class="section section--detail">

        <div class="detail-container">

            <!-- Form -->
            <div class="detail-main-container">
                <WorkerDetailForm ref="workerForm" :options="options" :notes="notes" :edit="edit" :listEdit="edit"
                    :daysOffList="daysOff" @daysOff="workerDaysOff" :daysOffDate="daysOffDate" />
                <EditButtons v-if="edit" toUpdate="Aggiorna operatore" @cancel="editsCancel"
                    resetText="Annulla modifiche" @validate="validate()" />
            </div>

            <!-- Side bar -->
            <div class="detail-aside-container">
                <AsideDetail @edit="workerEdit" @archive="workerArchive" @delete="workerDelete" @active="active"
                    :edit="edit" :status="options.status" />
            </div>
        </div>

        <!-- Modals -->
        <ProfileRemover v-if="erase" text="Elimina operatore" :profile="options" toDelete="l'operatore" @cancel="workerDelete" />
        <Modal v-if="archive" title="Operatore archiviato" text="Operatore archiviato con successo" @redirect="redirect" />
        <Modal v-if="daysOffError" title="Riposi" :text="errorMessage" @redirect="closeDaysOff" />
        <Modal v-if="updated" title="Operatore aggiornato" text="Operatore aggiornato con successo" @redirect="redirect" />
        <Modal v-if="reactivate" title="Operatore ripristinato" text="Operatore ripristinato con successo" @redirect="redirect" />
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrolla i dati o riprova più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import WorkerDetailForm from './forms/WorkerDetailForm.vue';
import AsideDetail from './AsideDetail.vue';
import EditButtons from './tools/EditButtons.vue';
import ProfileRemover from './tools/ProfileRemover.vue';
import WorkerService from '../services/workerService';
import DaysOffService from '../services/daysOffService';
import Modal from './tools/Modal.vue';
import dayjs from 'dayjs';
import { setWorker, setEmptyDaysOff, saveWorker } from '../setters';
import { daysOffErrorHandler } from '../dataService';

require('dayjs/locale/it')

</script>

<script>

export default {
    name: 'worker-details',
    components: {
        WorkerDetailForm,
        AsideDetail,
        EditButtons,
        ProfileRemover,
        Modal
    },
    props: {
        options: {
            default: null,
            type: Object
        },
        listEdit: {
            default: null,
            type: Boolean
        },
        notes: {
            default: null,
            type: Array
        },
        daysOff: {
            default: null,
            type: Object
        }
    },
    data() {
        return {
            archive: null,
            erase: null,
            edit: (this.listEdit) ? true : false,
            updated: false,
            reactivate: false,
            daysOffArray: null,
            daysOffDate: '',
            daysOffError: false,
            errorMessage: null,
            error: false
        }
    },
    computed: {
        daysOffData() {

            var data;

            if (this.daysOffArray !== null) {

                data = { ...this.daysOffArray }

            } else {

                data = { ...this.daysOff }

            }

            delete data.id
            delete data.created_at
            delete data.updated_at

            return data
        }
    },
    methods: {
        async workerArchive(bool) {

            var workerData = setWorker(this.options, 'archiviato', 'indeterminato', 'operatore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.options.id);

            if (Worker !== false) {
                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {
                    if (this.options.id == worker.id) {
                        worker.status = 'archiviato'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))

                this.archive = bool
            } else {
                this.error = true
            }
        },
        async active(bool) {
            this.reactivate = bool

            var workerData = setWorker(this.options, 'attivo', 'indeterminato', 'opratore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.options.id);

            if (Worker !== false) {
                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if (this.options.id == worker.id) {
                        worker.status = 'attivo'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
            } else {
                this.error = true
            }
        },
        workerEdit(bool) {
            this.edit = bool
        },
        workerDelete(bool) {
            this.erase = bool
        },
        workerDaysOff(array) {
            this.daysOffArray = array
        },
        editsCancel(bool) {
            this.edit = bool
            this.$router.go()
        },
        closeDaysOff() {
            this.daysOffError = false
        },
        async validate() {

            const workerForm = this.$refs.workerForm;
            const isFormValid = workerForm.validate();
            console.log(workerForm)


            if (isFormValid && !workerForm.v$.$error) {

                // WORKER DATA

                var workerData = setWorker(workerForm, 'attivo', 'indeterminato', 'operatore')

                const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.options.id);

                if (Worker !== false) {

                    var workersList = JSON.parse(localStorage.getItem('workersList'))

                    Array.from(workersList).map((worker) => {
                        if (this.options.id == worker.id) {

                            saveWorker(worker, workerData)
                        }
                    })
                    console.log(workersList)
                    localStorage.setItem('workersList', JSON.stringify(workersList))

                    // DAYS OFF DATA

                    var DaysOff;

                    if (this.daysOff !== null) {

                        this.daysOffData.year = dayjs().day(6).format('YYYY')
                        DaysOff = await DaysOffService.updateDaysOff(localStorage.getItem('token'), this.daysOff.id, this.daysOffData)

                    } else {

                        console.log(this.daysOffData)
                        var data = (Object.keys(this.daysOffData).length === 0) ? setEmptyDaysOff() : { ...this.daysOffData }
                        var year = dayjs().day(6).format('YYYY')
                        data.week_number = dayjs().week()
                        data.year = year
                        data.user_id = this.options.id

                        console.log(data)

                        DaysOff = await DaysOffService.addDayOff(localStorage.getItem('token'), data)
                    }

                    var handler = daysOffErrorHandler(DaysOff)

                    console.log(handler)

                    if (handler.error) {

                        this.daysOffError = handler.error
                        this.errorMessage = handler.message
                        this.daysOffDate = handler.date

                    } else {

                        this.updated = handler.updated
                        this.edit = handler.edit
                    }
                }else{
                    this.error = true
                }
            }
            else {
                console.log(workerForm.v$.$error)
                return
            }
        },
        redirect() {
            this.archive = false
            this.updated = false
            this.reactivate = false
            this.error = false
            this.$router.go()
        }
    }
}

</script>