<template>

    <div ref="scheduler" class="scheduler" :class="[(panoramic) ? ' panoramic' : '', (!block) ? ' allow-events' : '']">
        <Header :date="scheduleDate" />
        <Resource @saveInfo="saveInfo" :scheduleList="(!panoramic) ? scheduleList : scheduleList.slice(0, 4)" :actualShifts="actualShifts" />
    </div>

    <div v-if="panoramic">
        <div v-for="(scheduler, index) in scheduleList.slice(4 * (index + 1))" :key="4 * (index + 1)" class="scheduler" :style="(scheduleList.length > (4 * (index + 1))) ? 'margin-top : 30px' : 'margin-top : 0'"
            :class="(panoramic) ? ' panoramic' : ''">
            <Header v-if="scheduleList.length > (4 * (index + 1)) && panoramic" :date="scheduleDate" />
            <Resource v-if="scheduleList.length > (4 * (index + 1)) && panoramic" @saveInfo="saveInfo"
                :scheduleList="scheduleList.slice(4 * (index + 1), (4 * (index + 1)) + 4)" :actualShifts="actualShifts" />
        </div>
    </div>

    <EditShifts ref="editForm" v-if="visible && !panoramic" :morning="morning" :afternoon="afternoon" :evening="evening" :resource="resource"
        :date="date" :target="target" :timeError="timeError" :scheduleError="scheduleError" @close="close" @edit-shift="editShift"
        @delete-shift="deleteShift" />

    <div class="scheduler-buttons">
        <Button v-if="!panoramic && !home" text="Torna indietro" buttonType="" icon="arrow-back" :rounded="true" @click="$emit('back')" />
        <Button text="Vincoli operatori" buttonType="brand" icon="priority-high" :rounded="true" @click="$emit('verify')" />
        <Button text="Verifica riposi" buttonType="brand" icon="priority-high" :rounded="true" @click="$emit('daysOff')" />
        <Button text="Vincoli punti vendita" buttonType="brand" icon="priority-high" :rounded="true" @click="$emit('shopVerify')" />
        <Button  v-if="statusEditable && (!panoramic && !home)" text="Salva in bozza" buttonType="brand" icon="save" :rounded="true" @click="$emit('archive')" />
        <Button v-if="statusEditable && (!panoramic && !home)" text="Programma" buttonType="brand" icon="calendar-today" :rounded="true" @click="$emit('schedule')" />
        <Button v-if="!statusEditable && (!panoramic && !home)" text="Salva" buttonType="brand" icon="calendar-today" :rounded="true" @click="$emit('schedule')" />
    </div>

    <div v-if="panoramic && !home" class="scheduler-buttons">
        <Button text="Torna indietro" buttonType="" icon="arrow-back" :rounded="true" @click="$emit('back')" />
    </div>

    <Modal v-if="asyncError" title="Errore" text="Qualcosa non ha funzionato, clicca su ok per riaggiornare l'applicazione" @redirect="redirect" />

</template>

<script setup>
import Button from './tools/Button.vue';
import EditShifts from '../components/scheduler/EditShifts.vue';
import Header from '../components/scheduler/Header.vue';
import Modal from '../components/tools/Modal.vue'
import Resource from '../components/scheduler/Resource.vue';
import ShiftsObjects from '../services/shiftsObjects';
import ShiftsService from '../services/shiftsService';
import WorkerService from '../services/workerService';
import { totHoursCounter } from '../dataService'
import dayjs from 'dayjs';


const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

</script>

<script>

export default {
    name: 'VueScheduler',
    components: {
        Resource,
        Header,
        EditShifts,
    },
    props: {
        actualShifts: {
            default: false,
            tyoe: Boolean
        },
        scheduleDate: {
            default: new Date(),
            type: Date
        },
        weekNumber: {
            default: null,
            type: Number
        },
        year: {
            default: dayjs().day(6).format('YYYY'),
            type: String
        },
        scheduleId: {
            default: null,
            type: Number
        },
        init: {
            default: null,
            type: Array
        },
        panoramic: {
            default: false,
            type: Boolean
        },
        shops: {
            default: true,
            type: Boolean
        },
        home: {
            default: false,
            type: Boolean
        },
        updated: {
            default: false,
            type: Boolean
        },
        updatedScheduleList: {
            default: null,
            type: Array
        },
        worker: {
            default: null,
            type: Number
        },
        block : {
            default : false,
            type : Boolean
        },
        group_id : {
            default: null,
            type: Number
        },
        statusEditable : {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            afternoon: null,
            asyncError: false,
            date: null,
            evening: null,
            morning: null,
            resource: null,
            scheduleError: false,
            scheduleList: [],
            scheduling_id: null,
            shift_id: null,
            shop_id: null,
            target: null,
            timeError: false,
            user_id: null,
            visible: false,
        }
    },
    methods: {
        saveInfo(morning, afternoon, evening, resource, date, target, shop_id, scheduling_id, visible) {

            this.afternoon = afternoon
            this.date = date
            this.evening = evening
            this.morning = morning
            this.resource = resource
            this.scheduling_id = (this.scheduleId !== null) ? this.scheduleId : scheduling_id
            this.shift_id = (target == 'morning') ? morning.shift_id : (target == 'afternoon') ? afternoon.shift_id : evening.shift_id
            this.shop_id = shop_id
            this.target = target
            this.user_id = (target == 'morning') ? morning.id : (target == 'afternoon') ? afternoon.id : evening.id
            this.visible = visible

        },

        close() {
            this.visible = false
        },
        async editShift(time_start, time_end, workerId, shiftDate, message, shop_id, check_in, check_out) {

            const editForm = this.$refs.editForm
            const isFormValid = await editForm.validate()

            console.log('SHOP ID: ', shop_id)

            var shiftWorker

            var workersList = JSON.parse(localStorage.getItem('workersList'))

            Array.from(workersList).map((worker) => {
                if (worker.id == workerId) {
                    shiftWorker = worker
                }
            })

            if (isFormValid == true) {

                if (totHoursCounter(time_end, time_start) <= 0) {

                    if(this.user_id !== null) {

                        var shiftData = {
                            id: this.shift_id,
                            user_id: workerId,
                            shop_id: this.shop_id,
                            scheduling_id: (this.scheduleId !== null) ? this.scheduleId : this.scheduling_id,
                            date: this.date,
                            time_end: `${time_end}:00`,
                            time_start: `${time_start}:00`,
                            tot_hours: totHoursCounter(time_start, time_end),
                            period: (this.target == 'afternoon') ? '1' : (this.target == 'evening') ? '2' : '0',
                            message: message,
                            check_in: (check_in !== null) ? `${check_in}:00` : null,
                            check_out: (check_out !== null) ? `${check_out}:00` : null,
                        }

                        console.log('update tot hours: ', totHoursCounter(time_start, time_end))

                        const update = await ShiftsService.updateSingleShift(localStorage.getItem('token'), this.shift_id, shiftData)
                        
                        if(update !== false){

                            console.log(update)

                            var editShift = ShiftsService.editShifts(
                                this.scheduleList, 
                                this.shop_id, 
                                dayjs(shiftDate).format('DD-MM-YYYY'), 
                                time_start,
                                time_end,
                                shiftWorker.full_name,
                                workerId,
                                this.target,
                                message,
                                this.shift_id,
                                check_in,
                                check_out
                            );

                            this.visible = false
                            this.scheduleList = editShift;
                        }else{
                            this.scheduleError = true
                            setTimeout(() => {
                                this.scheduleError = false
                            }, 3000);
                        }

                    }else{

                        shiftData = {
                            check_in: (check_in !== null) ? `${check_in}:00` : null,
                            check_out: (check_out !== null) ? `${check_out}:00` : null,
                            date: this.date,
                            message: message,
                            period: (this.target == 'afternoon') ? '1' : (this.target == 'evening') ? '2' : '0',
                            role: shiftWorker.role,
                            scheduling_id: (this.scheduleId !== null) ? this.scheduleId : this.scheduling_id,
                            shop_id: this.shop_id,
                            shop_name: this.resource,
                            time_end: `${time_end}:00`,
                            time_start: `${time_start}:00`,
                            tot_hours: totHoursCounter(time_start, time_end),
                            user_id: workerId,
                            user_name: shiftWorker.full_name,
                        }

                        console.log('create tot hours: ', totHoursCounter(time_start, time_end))

                        const create = await ShiftsService.createSingleShift(localStorage.getItem('token'), shiftData);
                        var shift_id = create.id
                        
                        if(create == false){

                            this.scheduleError = true
                            setTimeout(() => {
                                this.scheduleError = false
                            }, 3000);

                        }else{

                            editShift = ShiftsService.editShifts(
                                this.scheduleList, 
                                this.shop_id, 
                                dayjs(shiftDate).format('DD-MM-YYYY'), 
                                time_start, 
                                time_end, 
                                shiftWorker.full_name, 
                                workerId, 
                                this.target, 
                                message, 
                                shift_id, 
                                check_in, 
                                check_out
                            );

                            this.scheduleList = editShift;
                            this.visible = false
                            this.timeError = false  
                        }
                    }
                } else {
                    this.timeError = true
                    setTimeout(() => {
                        this.timeError = false
                    }, 3000);
                }

            } else {
                console.log(editForm.v$.$error)
                return
            }
        },
        async deleteShift(time_start, time_end, workerId, shiftDate) {

            var shiftWorker = await WorkerService.getWorker(localStorage.getItem('token'), workerId)

            ShiftsService.deleteSingleShift(localStorage.getItem('token'), this.shift_id)

            var deleteShift = ShiftsService.deleteDashboardShifts(this.scheduleList, this.shop_id, dayjs(shiftDate).format('DD-MM-YYYY'), time_start, time_end, shiftWorker.full_name, this.target)

            this.scheduleList = deleteShift

            this.visible = false
        },
        redirect(){
            // localStorage.clear()
            this.$router.go()
        }
    },
    watch: {
        async shops(bool) {

            var weekNumber = (this.weekNumber !== null) ? this.weekNumber : dayjs().week()

            if (this.$route.path == '/') {

                if (bool == true) {

                    this.scheduleList = await ShiftsObjects.getShiftObject(localStorage.getItem('token'), this.year, weekNumber, this.group_id, this.scheduleId)
                } else {

                    this.scheduleList = await ShiftsObjects.getShiftObjectByWorker(localStorage.getItem('token'), this.year, weekNumber)
                }
            }
        },
        updated(bool) {

            if (bool == true) {
                this.scheduleList = this.updatedScheduleList
            }
        },

        async group_id(value){

            var weekNumber = (this.weekNumber !== null) ? this.weekNumber : dayjs().week()
            
            if(this.shops == true){
                this.scheduleList = await ShiftsObjects.getShiftObject(localStorage.getItem('token'), this.year, weekNumber, value, this.scheduleId)
            }else{
                this.scheduleList = await ShiftsObjects.getShiftObjectByWorker(localStorage.getItem('token'), this.year, weekNumber)
            }
        }
    },
    computed: {
        createShiftList() {
            return JSON.parse(localStorage.getItem('createShiftList'))
        }
    },
    async mounted() {

        var weekNumber = (this.weekNumber !== null) ? this.weekNumber : dayjs().week()

        if(this.group_id !== null){

            var scheduleList = await ShiftsObjects.getShiftObject(localStorage.getItem('token'), this.year, weekNumber, this.group_id, this.scheduleId)
            this.scheduleList = (scheduleList.length > 0) ? scheduleList : (this.init !== null) ? this.init : [];
        }

        if (this.updated == true) this.scheduleList = this.updatedScheduleList;

        else if (this.shops == false) {
            
            if(this.worker !== null && this.worker !== '') this.scheduleList = [await ShiftsObjects.getShiftObjectBySingleWorker(localStorage.getItem('token'), this.year, this.weekNumber, this.worker)];
            
            else this.scheduleList = await ShiftsObjects.getShiftObjectByWorker(localStorage.getItem('token'), this.year, this.weekNumber);
        }
    }
}

</script>