<template>
    <div class="main">
        <PageTitle :dashboard="true" @panoramic="panoramic" @workers="workers" @shops="shop" @actualShifts="isActualShifts" />
        <div class="dashboard-select">
            <FormSelect :dashboard-select="true" v-model:value="groupId" :item-list="schedulesList" />
        </div>
        <div class="window" style="padding-top: 30px;">
            <Scheduler :shops="shops" :panoramic="isPanoramic" :group_id="groupId" :schedule-date="options.start"
                :weekNumber="dayjs().week()" :home="true" :block="true" @verify="verifyConstraints" @shopVerify="verifyShops" @daysOff="openDaysOff" :actualShifts="actualShifts" />
        </div>
        
        <Constraints v-if="verify" :year="dayjs().day(1).year()" :week_number="dayjs().week()" :group_id="groupId" @close="close"/> 
        <DaysOffModal v-if="daysOff" :dashboard="true" :buttons="false" :week="dayjs().week()" :year="dayjs().year()" @close="close" />
        <ShopConstraints v-if="shopVerify" :year="dayjs().day(6).year()" :week_number="dayjs().week()" :group_id="groupId" :schedule-id="schedule_id" @close="close"/>
        <!-- <Modal v-if="update" title="Riposi aggiornati" text="Riposi aggiornati con successo" @redirect="update = false" /> -->

    </div>
</template>

<script setup>

import Constraints from '../components/Constraints.vue';
import DaysOffService from '../services/daysOffService';
import DaysOffModal from '../components/days-off/DaysOffModal.vue';
import FormSelect from '../components/tools/FormSelect.vue';
// import Modal from '../components/tools/Modal.vue';
import PageTitle from '../components/PageTitle.vue';
import ScheduleService from '../services/scheduleService';
import Scheduler from '../components/Scheduler.vue';
import ShopConstraints from '../components/ShopConstraints.vue';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import dayjs from 'dayjs';

dayjs.extend(dayOfYear)
require('dayjs/locale/it')
const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

</script>

<script>

export default {
    name: 'dashboard-informitalia',
    components: {
        Scheduler,
        PageTitle,
        DaysOffModal,
        FormSelect,
        Constraints,
        // Modal,
    },

    data() {
        return {
            actualShifts: false,
            daysOff: false,
            groupId: null,
            isPanoramic: false,
            options: {
                start: dayjs().format('YYYY-MM-DD')
            },
            schedule_id: null,
            schedulesList: [],
            shopVerify: false,
            shops: true,
            update: false,
            verify: false,
        }
    },
    methods: {
        panoramic(bool) {
            this.isPanoramic = bool
        },
        workers() {
            this.shops = false
        },
        shop() {
            this.shops = true
        },
        isActualShifts(bool){
            this.isPanoramic = true
            this.actualShifts = bool
        },
        verifyConstraints(){
            this.verify = true
        },
        verifyShops(){
            this.shopVerify = true
        },
        close(){
            this.verify = false
            this.shopVerify = false
            this.daysOff = false
        },
        openDaysOff(){
            this.daysOff = true
        },
        async updateDaysOff(daysOff){
            const DaysOff = await DaysOffService.updateAllDaysOff(localStorage.getItem('token'), daysOff)
            if(DaysOff !== false){
                this.update = true
                this.daysOff = false
            }
        }
    },
    watch: {
        groupId(value){
            Array.from(JSON.parse(localStorage.getItem('schedulesList'))).map((schedule) => {
                if(schedule.week_number == dayjs().week() && schedule.year == dayjs().year() && schedule.group_id == value){
                    this.schedule_id = schedule.id
                }
            })
        }
    },
    async mounted() {

        var usedGroups = await ScheduleService.getUsedGroups(
            localStorage.getItem('token'),
            dayjs().year(),
            dayjs().week()
        )

        const allGroups = JSON.parse(localStorage.getItem('groupsList'))

        if (allGroups.length > 0) {
            Array.from(usedGroups).map((groupId) => {
                Array.from(allGroups).map((group) => {
                    if (groupId == group.id && group.status == 'attivo') {
                        this.schedulesList.push({
                            group_id: groupId,
                            group_name: group.group_name
                        })
                    }
                })
            })
        }

        this.groupId = (this.schedulesList.length > 0) ? this.schedulesList[0].group_id : null

        Array.from(JSON.parse(localStorage.getItem('schedulesList'))).map((schedule) => {
            if(schedule.week_number == dayjs().week() && schedule.year == dayjs().year() && schedule.group_id == this.groupId){
                this.schedule_id = schedule.id
            }
        })
    }
}

</script>