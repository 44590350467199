// Axios
import axios from "axios";
import dayjs from "dayjs";
import StatusService from "./statusService";
import { endpoint, totHoursCounter } from "../dataService";

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class ShiftsService {

    constructor() {

        this.url = `${endpoint()}/api/v1/shifts/`

    }


    ///////////////////////////////////////////////
    // GET ALL SHIFTS
    //////////////////////////////////////////////

    async getShifts(token, year, weekNr, groupId) {

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(`${this.url}${year}/${weekNr}/${groupId}`, config)

            .then(function (response) {

                console.log('SHIFTS WEEK' + ' ' + weekNr + ': ', response.data.data)
                return response.data.data

            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

    async getShiftsAllWeek(token, year, weekNr) {

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(`${this.url}${year}/${weekNr}`, config)

            .then(function (response) {

                console.log('SHIFTS WEEK' + ' ' + weekNr + ': ', response.data.data)
                return response.data.data

            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

    //////////////////////////////////
    // FUNCTION TO CREATE SINGLE SHIFT
    //////////////////////////////////

    async createSingleShift(token, userData) {
        
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.post(this.url, userData, config)

            .then(function (response) {

                console.log(response.data.data.id)
                return response.data.data

            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

    //////////////////////////////////
    // FUNCTION TO CREATE SINGLE SHIFT
    //////////////////////////////////

    async updateSingleShift(token, id, userData) {

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.put(this.url + id, userData, config)

            .then(function (response) {

                console.log(response.data.data)
                return response.data.data
            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

    ////////////////////////////////////////////////////
    // FUNCTION TO EDIT THE OBJECT OF A SCHEDULED SHIFT 
    ////////////////////////////////////////////////////

    editShifts(scheduleList, shop_id, date, time_start, time_end, user_name, id, 
        target, message, shift_id, check_in, check_out) {

        for (var j = 0; j < scheduleList.length; j++) {

            if (shop_id == scheduleList[j].shop_id) {

                Array.from(scheduleList[j].shifts).map((currentShift) => {
                    if (dayjs(currentShift.date).format('DD-MM-YYYY') == date && target == 'morning') {

                        currentShift.morning.check_in = (check_in !== null) ? check_in.substring(0, 5) : null
                        currentShift.morning.check_out = (check_out !== null) ? check_out.substring(0, 5) : null
                        currentShift.morning.id = id
                        currentShift.morning.message = message
                        currentShift.morning.shift_id = shift_id
                        currentShift.morning.time_end = time_end.substring(0, 5)
                        currentShift.morning.time_start = time_start.substring(0, 5)
                        currentShift.morning.worker = user_name
                    }
                    if (dayjs(currentShift.date).format('DD-MM-YYYY') == date && target == 'afternoon') {

                        currentShift.afternoon.check_in = (check_in !== null) ? check_in.substring(0, 5) : null
                        currentShift.afternoon.check_out = (check_out !== null) ? check_out.substring(0, 5) : null
                        currentShift.afternoon.id = id
                        currentShift.afternoon.message = message
                        currentShift.afternoon.shift_id = shift_id
                        currentShift.afternoon.time_end = time_end.substring(0, 5)
                        currentShift.afternoon.time_start = time_start.substring(0, 5)
                        currentShift.afternoon.worker = user_name
                    }
                    if (dayjs(currentShift.date).format('DD-MM-YYYY') == date && target == 'evening') {

                        currentShift.evening.check_in = (check_in !== null) ? check_in.substring(0, 5) : null
                        currentShift.evening.check_out = (check_out !== null) ? check_out.substring(0, 5) : null
                        currentShift.evening.id = id
                        currentShift.evening.message = message
                        currentShift.evening.shift_id = shift_id
                        currentShift.evening.time_end = time_end.substring(0, 5)
                        currentShift.evening.time_start = time_start.substring(0, 5)
                        currentShift.evening.worker = user_name
                    }
                })
            }
        }

        return scheduleList
    }

    ////////////////////////////////////////////////////
    // FUNCTION TO DELETE THE OBJECT OF A SCHEDULED SHIFT 
    ////////////////////////////////////////////////////

    deleteDashboardShifts(scheduleList, shop_id, date, time_start, time_end, user_name, target) {

        for (var j = 0; j < scheduleList.length; j++) {

            if (shop_id == scheduleList[j].shop_id) {

                Array.from(scheduleList[j].shifts).map((currentShift) => {
                    if (dayjs(currentShift.date).format('DD-MM-YYYY') == date && target == 'morning') {

                        currentShift.morning.shift_id = null
                        currentShift.morning.message = null
                        currentShift.morning.time_start = null
                        currentShift.morning.time_end = null
                        currentShift.morning.worker = null
                        currentShift.morning.id = null
                        currentShift.morning.check_in = null
                        currentShift.morning.check_out = null
                        currentShift.morning.status = null
                        currentShift.morning.tot_hours_worked = null
                    }
                    if (dayjs(currentShift.date).format('DD-MM-YYYY') == date && target == 'afternoon') {

                        currentShift.afternoon.shift_id = null
                        currentShift.afternoon.message = null
                        currentShift.afternoon.time_start = null
                        currentShift.afternoon.time_end = null
                        currentShift.afternoon.worker = null
                        currentShift.afternoon.id = null
                        currentShift.afternoon.check_in = null
                        currentShift.afternoon.check_out = null
                        currentShift.afternoon.status = null
                        currentShift.afternoon.tot_hours_worked = null
                    }
                    if (dayjs(currentShift.date).format('DD-MM-YYYY') == date && target == 'evening') {

                        currentShift.evening.shift_id = null
                        currentShift.evening.message = null
                        currentShift.evening.time_start = null
                        currentShift.evening.time_end = null
                        currentShift.evening.worker = null
                        currentShift.evening.id = null
                        currentShift.evening.check_in = null
                        currentShift.evening.check_out = null
                        currentShift.evening.status = null
                        currentShift.evening.tot_hours_worked = null
                    }
                })
            }
        }

        return scheduleList
    }

    ////////////////////////////////////
    // FUNCTION TO DELETE A SINGLE SHIFT 
    ////////////////////////////////////

    async deleteSingleShift(token, id) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }
        

        await axios.delete(this.url + id, config)

            .then(function (response) {

                console.log('SHIFT DELETED', response.data.data)
                return response.data.data

            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

    //////////////////////
    // WORKERS CONSTRAINTS 
    //////////////////////

    async getSingleWorkerWeekTotHours(token, year, weekNr) {
        const allShifts = await this.getShiftsAllWeek(token, year, weekNr)
        var totHours = []
        var arrayIds = []

        Array.from(allShifts).map((shift) => {
            if(!arrayIds.includes(shift.user_id)){
                arrayIds.push(shift.user_id)
            }
        })
            
        arrayIds.map((id) => {
            totHours.push({
                user_id: id,
                tot_hours: 0,
                num_hours: null,
                status: 'less'
            })
        })

        totHours.map((worker) => {
            Array.from(allShifts).map((shift) => {
                if (worker.user_id == shift.user_id) {
                    worker.tot_hours += totHoursCounter(shift.time_start, shift.time_end)
                }
            })
        })

        return totHours

    }

    checkTotHours(workers, totHours) {

        Array.from(workers).map((worker) => {
            Array.from(totHours).map((hours) => {
                if (worker.id == hours.user_id) {
                    
                    if(workers.num_hours !== null){
                        hours.num_hours = worker.num_hours
                    }else{
                        hours.num_hours = null
                    }
                    hours.user_name = worker.full_name
                }
            })
        })

        Array.from(totHours).map((worker) => {
            if(worker.num_hours !== null){
                if (parseInt(worker.num_hours) == worker.tot_hours) {
                    worker.status = 'ok'
                } else if (worker.tot_hours > parseInt(worker.num_hours)) {
                    worker.status = 'more'
                } 
            } else {
                worker.status = 'Nessun monte ore'
            }
        })

        return totHours
    }

}

export default new ShiftsService();